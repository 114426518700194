const config = {
  apiGateway: {
    REGION: 'eu-west-2',
    URL: 'https://jf0z6t2e57.execute-api.eu-west-2.amazonaws.com/ifs-demo-v2',
  },
  cognito: {
    REGION: 'eu-west-2',
    USER_POOL_ID: 'eu-west-2_F4hjmZG7l',
    APP_CLIENT_ID: '2k1mgu09nvsk4hp80m711h6ghj',
    IDENTITY_POOL_ID: 'undefined',
  },
};
export default config;
